import { Routes, Route, BrowserRouter } from "react-router-dom";
import { isMobile } from "react-device-detect";

import Layout from "./components/Layout.jsx";
import Home from "./components/Home.jsx";
import Visaq from "./projects/Visaq.jsx";
import Hamilton from "./projects/Hamilton.jsx";
import Givewithgifted from "./projects/Givewithgifted.jsx";
import Stroke from "./projects/Stroke.jsx";
import Digdug from "./projects/Digdug.jsx";
import Wordle from "./projects/Wordle.jsx";
import Drawing from "./projects/Drawing.jsx";
import Spectrumx from "./projects/Spectrumx.jsx";
import Devfest2025 from "./projects/Devfest2025.jsx";

export default function App() {
  if (isMobile) {
    return <div> This content is available only on desktop (for now).</div>;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />

          <Route path="web">
            <Route path="visaq" element={<Visaq />} />
            <Route path="hamilton-college-iss" element={<Hamilton />} />
            <Route path="give-with-gifted" element={<Givewithgifted />} />
          </Route>

          <Route path="misc">
            <Route path="stroke" element={<Stroke />} />
            <Route path="digdug" element={<Digdug />} />
            <Route path="drawing" element={<Drawing />} />
            <Route path="wordle-solver" element={<Wordle />} />
          </Route>

          <Route path="hack">
            <Route path="spectrumx" element={<Spectrumx />} />
            <Route path="devfest2025" element={<Devfest2025 />} />
          </Route>

          <Route path="*" element={<>NO SUCH PROJECT</>} />
        </Route>

        <Route
          path="spectrumx-slide"
          element={
            <iframe
              src={require("./pictures/spectrumx.pdf")}
              style={{ width: "100vw", height: "100vh" }}
            />
          }
        />
      </Routes>
    </BrowserRouter>
  );
}
